.root {
  width: 100%;
  overflow-x: auto;
}

.table {
  padding: 5px 10px;
  border: 1px solid #666;
  background-color: #fff;
  margin: 0 auto;
}

.header {
  border-bottom: 1px solid #666;
  padding: 2px 5px;
  text-transform: uppercase;
}

.row {
  border-bottom: 1px solid #666;
  padding: 2px 5px;
}

.row > th {
  text-transform: uppercase;
}

.row > td, .row > th {
  border-right: 1px solid #666;
  padding: 1px;
  text-align: center;
}

.row:hover {
  background-color: #eee;
}

.wrapperPagination {
  margin: 10px 0px;
  position: relative;
  padding: 20px 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  height: 100px;
  align-items: center;
  background-color: white;
  display: flex;
  flex-direction: row;
  position: relative;
}

.pagination {
  position: absolute;
  margin-right: 20px;
}

.button {
  cursor: pointer;
  text-decoration: underline;
  color: #5664d2;
  background: none;
  border: none;
  text-transform: capitalize;
}


