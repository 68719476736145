@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');

@font-face {
  font-family: 'Gotham';
  src: url(./fonts/Gotham-Medium.otf);
}

html {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: 'Montserrat', 'Gotham', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
}

body,
#root {
  height: 100%;
  min-height: 100%;
  padding: 0;
  margin: 0;
}

table,
th,
td {
  border-collapse: collapse;
}

button {
  font-family: 'Montserrat', 'Gotham', sans-serif;
}

a {
  color: #e30613;
}
