.root {
  width: 120px;
  margin-right: 20px;
}

.container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 110px;
}

@media only screen and (max-width: 1240px) {
  .container {
    width: initial;
  }
}

.label {
  bottom: 30px;
  user-select: none;
  min-height: 1.2em;
}

.label.error {
  color: #ff2400;
}

.datepicker {
  padding: 6px 10px;
  border-radius: 6px;
  border: 1px solid #ddd;
  margin: 5px 0;
  width: calc(100% - 20px);
  font-family: 'Gotham';
  font-size: 11px;
}

.datepicker.error {
  border-color: #ff2400;
}

.datepicker:focus {
  outline: 0;
}

.header {
  display: flex;
  justify-content: center;
}

.header select {
  background-color: #f0f0f0;
  border: 0;
  margin: 0 5px;
}

.header button {
  background-color: #f0f0f0;
  border: 0;
  cursor: pointer;
  margin: 0 10px;
}

.icon {
  position: absolute;
  right: 5px;
  top: 2.3em;
  cursor: text;
}

.errorLabel {
  font-size: 0.8em;
  height: 1.4em;
  display: block;
  color: #ff2400;
}
