.staticPage {
  padding: 5px 10px;
  border: 0.2px solid #eee;
  color: #000;
  cursor: pointer;
  margin: 0 2px;
}

.page {
  padding: 5px 10px;
  border: 0.2px solid #eee;
  color: #000;
  cursor: pointer;
  margin: 0 2px;
}

.page:hover {
  background: #5664d2;
  color: #fff;
}

.selectedPage {
  background: #5664d2;
  color: #fff;
}

.disabled {
  color: #999;
  cursor: default;
}

.disabled:hover {
  background: #fff;
  color: #999;
}

.hidden {
  display: none;
}
