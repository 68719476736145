.input {
  padding: 10px 20px;
  border-radius: 6px;
  margin-top: 10px;
  border: thin solid #ddd;
  font-size: 20px;
  height: 54px;
  outline: 0;
}

.input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #999;
  opacity: 1; /* Firefox */
  font-family: 'Montserrat';
}

.input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #999;
}

.input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #999;
}

.input:focus {
  outline: 0;
}

.error {
  border: thin solid #ff2400;
}
.errorMessage {
  color: #ff2400;
  margin-left: 2px;
  font-size: 0.9em;
  min-height: 1.2em;
  line-height: 1.2em;
  margin-top: 4px;
}

.wrapperLink {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.link {
  position: relative;
  bottom: 35px;
  margin-right: 20px;
  z-index: 1;
  margin-left: auto;
  font-size: 16px;
  height: 0;
  font-weight: 500;
}

@media only screen and (max-width: 1024px) {
  .input {
    padding: 5px 15px;
    font-size: 16px;
    height: 24px;
  }

  .link {
    bottom: 28px;
    margin-right: 16px;
    font-size: 12px;
  }
}

@media only screen and (max-height: 850px) and (min-width: 1024px) {
  .input {
    padding: 8px 16px;
    font-size: 16px;
    height: 20px;
  }
  .errorMessage {
    font-size: 12px;
  }

  .link {
    bottom: 30px;
    margin-right: 15px;
    font-size: 14px;
  }
}

@media only screen and (max-height: 550px) {
  .input {
    padding: 8px 16px;
    font-size: 16px;
    height: 20px;
  }
  .errorMessage {
    font-size: 12px;
  }

  .link {
    bottom: 30px;
    margin-right: 15px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 400px) {
  .link {
    bottom: 25px;
    margin-right: 15px;
    font-size: 0.8em;
  }
}
