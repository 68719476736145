.root {
  width: 100%;
}

.toolbar {
  padding: 5px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
  margin: 20px 0px;
}