.form {
  background-color: #fff;
  width: 100%;
}

.card {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 10px;
  width: 100%;
}


@media only screen and (max-width: 991px) {
  .card {
    flex-direction: column;
    align-items: flex-start;
  }
  .card > div{
    margin: 0px 20px;
  }
}