.container {
  flex: 1;
  width: 100%;
  margin-top: 20px;
}

.toolbar {
  display: flex;
  background-color: #fff;
  padding: 5px;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
}

.datepicker {
  width: 120px;
}

.row {
  display: flex;
  flex-direction: row;
}
