.toolbar {
  background-color: #fff;
  padding: 5px 10px;
  width: 85vw;
}


.form {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 54px;
}