.root {
  padding: 5px;
  background-color: white;
  margin: 0px auto;
  border-top: 1px solid #ddd;
}

.container {
  border-radius: 10px;
  border: 1px solid #ddd;
  padding: 5px;
  background-color: white;
  margin: 0px auto;
}

.title {
  font-weight: 600;
  font-size: 18px;
  margin: 15px;
  font-family: 'Gotham';
  text-transform: lowercase;
}

.message {
  margin: 15px;
}
