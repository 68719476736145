.button {
  padding: 10px 40px;
  border-radius: 6px;
  font-size: 18px;
  border: 0;
  cursor: pointer;
  background-color: #5664d2;
  color: #fff;
  text-transform: uppercase;
  font-family: 'Gotham';
  display: flex;
  justify-content: center;
  align-items: center;
}

.button:disabled {
  background-color: #999;
  cursor: auto;
}

.loading {
  cursor: auto;
  background-color: #5664d2 !important;
}

@media only screen and (max-width: 991px) {
  .button {
    width: 100%;
    padding: 6px 20px;
    font-size: 16px;
  }
}
