.login {
  width: 100%;
  display: flex;
}

.form {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.title {
  text-align: left;
  font-size: 1.6em;
  font-weight: 600;
  font-family: 'Gotham';
  margin-bottom: 10px;
}

.action {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
}

.button {
  margin-right: 20px !important;
  margin-bottom: 5px;
}

.link {
  font-weight: 600;
}

@media only screen and (max-width: 991px) {
  .action {
    display: block;
  }

  .action > div {
    text-align: right;
    font-size: 0.9em;
    margin-top: 10px;
  }
}
