.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 1.5em 10px 0px 10px;
}

.select {
  position: relative;
  display: inline-block;
}

.input {
  border: 0.1px solid #888;
  color: #000;
  background-color: #fff;
  border-radius: 8px;
  padding: 2px 10px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 200px;
  height: 45px;
  margin-top: -3px;
  font-family: 'Montserrat';
  font-weight: 300;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
  outline: 0;
}
.input::placeholder {
  font-size: 18px;
}


@media only screen and (max-width: 991px) {
  .input {
    min-width: 100%;
  }
}

.input:hover {
  background-color: #fefefe;
}

.input.error {
  border: 0.1px solid #ff2400 !important;
}

@media only screen and (max-width: 991px) {
  .input {
    width: 100%;
  }
}

.icon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 10px;
}

.selectContent {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 200px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 2;
  overflow-y: auto;
  padding: 5px;
  min-height: 5em;
  max-height: 500px;
}

.selectContent a:hover {
  background-color: #ddd;
}

@media only screen and (max-width: 991px) {
  .selectContent {
    width: calc(100% - 10px);
  }
}

@media only screen and (max-height: 850px) {
  .selectContent {
    max-height: 300px;
  }
}

.selectShow {
  display: block;
}

.option {
  color: black;
  padding: 5px 10px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.option:hover {
  background-color: #ddd;
}

.errorMessage {
  margin-top: 3px;
  font-size: 0.8em;
  height: 1.4em;
  display: block;
  color: #ff2400;
}
