
.root {
  display: flex;
  justify-content: center;
  padding: 40px;
  outline: 0;
  height: 100%;
  max-height: 55vh;
}

.container {
  width: 100%;
  height: 100%;
  max-height: 55vh;
}

.title {
  font-size: 20px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.field {
  padding: 5px 0px;
}

.select {
  margin-left: 10px;
}

.button {
  margin-top: 30px;
}